<script setup lang="ts">
const { t, locale } = useI18n()
const LEGAL_PAGES_PATH_PREFIX = '/legal'

const { data: navigation } = await useAsyncData('navigation', () => fetchContentNavigation({ where: [{ _locale: locale.value }] }), {
  transform: (data) => {
    let nav = data?.map(nav => ({ ...nav, title: nav.title })).filter(nav => !nav._path.includes(LEGAL_PAGES_PATH_PREFIX))

    let usefulLinksNavigation = nav.find(nav => nav._path === '/useful-links')

    if (usefulLinksNavigation) {
      usefulLinksNavigation = { ...usefulLinksNavigation }
      const children = usefulLinksNavigation.children ?? []

      if (!children.some(child => child._path === '/account'))
        children.unshift({ _path: '/account', title: t('page.account.title') })

      usefulLinksNavigation.children = children
      nav = nav.map(item => item._path === usefulLinksNavigation?._path ? usefulLinksNavigation : item)
    }

    const legal = data.find(nav => nav._path.includes(LEGAL_PAGES_PATH_PREFIX))?.children ?? []

    return { footer: nav, legal }
  },
})

const year = computed(() => new Date().getFullYear())

const socials = computed(() => {
  return Object.values(useRuntimeConfig().public.socials)
})
</script>

<template>
  <div
    data-testid="footer"
    class="mt12 flex flex-col intrinsic-size-[2500px] content-visibility-auto lg:intrinsic-size-[2000px] xl:intrinsic-size-[1500px]"
  >
    <!--    -->
    <div>
      <NewsletterSubscription />
    </div>

    <div class="solid my6 wfull border-t border-slate-200" />

    <nav class="m-auto max-w-[1024px] wfull flex flex-wrap">
      <div
        data-testid="contactsSection"
        class="box-border grow-1 basis-[33.333%] p6"
      >
        <NuxtLinkLocale
          to="/"
          data-testid="footerLogo"
          class="h8 max-h-10 min-w-15 w-37.5 flex translate-x-6 scale-140 items-center gap-0.5"
        >
          <Logo class="block h10 max-w-33 wfull shrink-0 grow-0" />
        </NuxtLinkLocale>

        <div data-testid="contactLinks" class="mt8" />

        <div data-testid="horizontalList" class="relative box-border wfull of-hidden p0">
          <div data-testid="socialLinks" class="static box-border max-w-full wfull flex of-hidden of-x-auto no-scrollbar scroll-timeline-[--horizontal-list-scroll-timeline_inline]">
            <NuxtLink
              v-for="social in socials" :key="social.href"
              data-testid="link"
              aria-label="Sociální sítě"
              class="mx2 my4 flex plain-link"
              target="_blank"
              rel="noreferrer"
              :to="social.href"
            >
              <Icon :name="social.icon" class="block h6 w6 shrink-0 grow-0 basis-6" />
            </NuxtLink>
          </div>
        </div>
      </div>

      <div data-testid="customerCareLinks" class="box-border min-w-[320px] grow-1 basis-[33.333%] p6">
        <span class="mb8 block text-xs text-primary fw600 uppercase">
          {{ t('page.customer_service.title') }}
        </span>
        <NuxtLinkLocale
          v-for="nav in navigation?.footer" :key="nav._path"
          :to="nav._path"
          data-testid="link"
          class="mb4 flex items-center plain-link"
        >
          <span class="inline-block text-sm fw500">
            {{ nav.title }}
          </span>
        </NuxtLinkLocale>
      </div>

      <div data-testid="secureShoppingCertificates" class="box-border grow-1 basis-[33.333%] p6">
        <span class="mb8 block text-xs text-primary fw600 uppercase">
          {{ t('checkout.secure_checkout') }}
        </span>
        <a href="https://www.rapidssl.com/" data-testid="link" class="mb4 flex items-center plain-link" target="_blank" rel="noreferrer">
          <Icon
            name="ri:lock-2-fill"
            class="mr2 aspect-square h6 w6 rd-0 align-bottom mix-blend-darken grayscale"
          />
          <span class="inline-block text-sm fw500"> {{ t('checkout.security_encryption_level') }}</span>
        </a>
      </div>
    </nav>

    <div class="mt8 flex flex-col bg-slate-100 p4" data-testid="legalLinks">
      <nav class="mb2 flex flex-row flex-wrap justify-center">
        <NuxtLinkLocale
          v-for="nav in navigation?.legal" :key="nav._path"
          :href="nav._path"
          data-testid="link"
          class="mx4 my2 inline text-xs fw500 plain-link"
        >
          {{ nav.title }}
        </NuxtLinkLocale>
      </nav>
      <span class="block text-center text-xs text-slate-400 fw500">
        © {{ year }} VEROC Brands Group a.s.
      </span>
    </div>
  </div>
</template>
