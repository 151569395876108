<script setup lang="ts">
import type { UnwrapRef } from 'vue'
import { upperFirst } from 'scule'

const { t, locale, locales, setLocale } = useI18n()

const switchLocalePath = useSwitchLocalePath()

const availableLocales = computed(() => locales.value.map((l: any) => {
  return {
    ...l,
    label: l.country,
    nativeName: upperFirst(l.nativeName),
  }
}))

const selectedLocale = ref(locale.value)
const localeFlagMap: Record<UnwrapRef<typeof selectedLocale>, string> = {
  en: 'i-circle-flags:us',
  cs: 'i-circle-flags:cz',
  sk: 'i-circle-flags:sk',
  hu: 'i-circle-flags:hu',
  pl: 'i-circle-flags:pl',
  ro: 'i-circle-flags:ro',
}

async function handleClick(locale: string) {
  await setLocale(locale)
  switchLocalePath(locale)
  window.location.reload()
}
</script>

<template>
  <UiPopover>
    <div class="relative cursor-pointer" role="region" :aria-label="t('general.language_and_currency.label')">
      <UiPopoverTrigger
        id="language-button"
        class="hfull flex cursor-pointer items-center bg-accent/50 px-2 text-slate outline-none"
        n="borderless"
        data-testid="languageCountrySwitch"
      >
        <NIcon :icon="localeFlagMap[selectedLocale]" alt="flag" class="n-icon-button mr2 n-icon" />
        <span class="text-slate fw600 leading-none">{{ availableLocales.find((l) => l.code === selectedLocale)?.label }}</span>
      </UiPopoverTrigger>
    </div>

    <UiPopoverContent>
      <div v-for="loc in availableLocales" :key="loc.code" class="p1">
        <div class="cursor-pointer" @click="handleClick(loc.code)">
          <div class="group w-full flex items-center rounded-md p2 text-sm text-slate-600 fw600 hover:bg-accent/50 hover:text-slate-900">
            <NIcon :icon="localeFlagMap[loc.code]" alt="flag" class="n-icon-button mr2 n-icon" />
            {{ loc.label }}
          </div>
        </div>
      </div>
    </UiPopoverContent>
  </UiPopover>
</template>
